import axios from "~/plugins/axios";

export default {
  getPersonalCode() {
    return axios
      .get("/ebsn/api/tell-friends/personal-code")
      .then(data => data.data.data)
      .catch(error => error);
  }
};
